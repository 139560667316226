import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import Layout from '../layouts/Layout';
import InfoPage from '../templates/InfoPage';
import { ReviewCard } from '../components/ReviewCard';

const Section = styled.div`
  margin-bottom: 4rem;

  > div {
    margin-bottom: 2rem;
  }
`;

const Reviews = ({ data }) => {
  return (
    <Layout>
      <SEO title="Reviews" />
      <InfoPage span="Reviews" title="Kind words from our clients" titleWidth="10">
        <Section>
          {data.contentYaml.reviews.map((review, index) => (
            <ReviewCard key={index}
              review={review.review}
              reviewer={review.reviewer}
            />
          ))}
        </Section>
      </InfoPage>
    </Layout>
  );
};

export default Reviews;

export const query = graphql`
  query Reviews {
    contentYaml {
      reviews {
        review
        reviewer
      }
    }
  }
`;
